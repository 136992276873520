import React, { useState } from 'react';
import {
    Breadcrumbs,
    ButtonGoBack,
    ContainerTaps,
    Layout,
    SectionContentPage,
    SEO
} from '@componentsShared';
import { colorText } from '@utils/colorText';
import { ContainerPage, ContentPage, ContentPageBody, ContentPageHeader } from './styled';
import siteUrl from '@utils/siteUrl';
import { IPropsPageDynamic, TSections } from './types';
import Viewport from 'src/shared/hooks/viewport';

const PageDynamic = (props: IPropsPageDynamic) => {
    const { pageContext } = props;
    const {
        slug,
        data: { metaData, title, sections, icon, breadcrumbTitle }
    } = pageContext;
    const [tapOpen, setTapOpen] = useState<{ title: string; sections: TSections[] }>({
        title: sections[0].title,
        sections: sections[0].secciones
    });

    const viewport = Viewport();

    let crumbs = [
        {
            crumbLabel: 'inicio',
            pathname: '/'
        },
        {
            crumbLabel: breadcrumbTitle,
            pathname: siteUrl + '/' + slug
        }
    ];

    return (
        <Layout>
            <SEO
                title={metaData.title}
                description={metaData.descripcion}
                keyWords={metaData.keyWords}
                image={metaData.image.file.url}
                slug={siteUrl + '/' + slug}
            />
            <ContainerPage>
                {viewport.desktop ? <Breadcrumbs crumbs={crumbs} /> : <ButtonGoBack />}
                <ContentPage>
                    <ContentPageHeader>
                        <img src={icon.file.url} alt={icon.description} />
                        <h1 dangerouslySetInnerHTML={{ __html: colorText(title) }} />
                    </ContentPageHeader>
                    <ContentPageBody>
                        <ContainerTaps
                            tapOpen={tapOpen}
                            setTapOpen={setTapOpen}
                            sections={sections}
                        />
                        {tapOpen.sections.map((section, key) => (
                            <SectionContentPage
                                text={section.text.raw}
                                isEven={key % 2 === 0}
                                img={section.mediaFile?.file}
                                video={section.video}
                            />
                        ))}
                    </ContentPageBody>
                </ContentPage>
            </ContainerPage>
        </Layout>
    );
};

export default PageDynamic;
