import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const ContainerPage = styled.div`
    width: 100%;
    padding: 13px 10px;
    display: flex;
    max-width: 1366px;
    flex-direction: column;

    ${breakpoint('md')({
        padding: '28px 67px'
    })}
`;

export const ContentPage = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 10px 26px;
`;

export const ContentPageHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    border-bottom: 1px solid ${(props) => props.theme.colors.disabled.gray};
    padding-bottom: 24px;
    text-align: center;

    & > img {
        margin-bottom: 14px;
        display: none;

        ${breakpoint('md')({
            display: 'block'
        })}
    }

    & > h1 {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 120%;

        span {
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }
`;

export const ContentPageBody = styled.div`
    width: 100%;
    height: auto;
`;
